<template>
	<div class="order-now" v-if="show" @click="$router.push('/order')">
		<b class="mx-3 order-now-font">{{ t('common.orderDeliveryNow') }}</b>
	</div>
</template>
<script setup>
import { watch, ref } from 'vue';
import { useRoute } from 'vue-router';
const route = useRoute();
const show = ref(false);
watch(
	() => route.path,
	(val) => {
		if (val === '/order' || val === '/detail') {
			show.value = false;
		} else show.value = true;
	},
	{ immediate: true }
);
</script>
<style>
.order-now {
	cursor: pointer;
	position: fixed;
	top: 100px;
	right: 0;
	color: #d98073;
	background-color: #fff;
	padding: 4px;
	border-radius: 32px 0 0 32px;
}

.heartbeat {
	position: absolute;
	left: 4px;
	background-color: #ff7b68;
	border-radius: 32px;
	width: 32px;
	height: 32px;
	opacity: 0.75;
	animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes ping {
	75%,
	100% {
		transform: scale(2);
		opacity: 0;
	}
}
@media (min-width: 960px) {
	.order-now-font {
		font-size: 1.1rem;
	}
}
</style>
